import React from 'react';
import Generic from '../../../components/287634/success/generic';

const Success = () => {
  return (
    <Generic
      h1={`Tu código de seguridad fue modificado con éxito.`}
      h2="Código de seguridad"
      imgSrc="/theme/assets/images/status/pin-success.svg"
    />
  );
};

export default Success;
